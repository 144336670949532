import React from 'react';
import axios from 'axios';
import './App.css';

const baseUrl = 'https://plang2kwa.maesalab.net';
const dataUrl = baseUrl + '/counter.json';
const actionUrl = baseUrl + '/action.php';

const ax = axios.create({ baseURL: baseUrl });

class App extends React.Component {

  state = { data: {} }

  async componentDidMount() {
    await this.fetch();
  }

  async fetch() {
    const data = (await ax.get(dataUrl + `?time=${(new Date()).getTime()}`)).data;
    this.setState({ data });
  }

  async increment(target) {
    const data = (await ax.post(actionUrl, { do: 'increment', target }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }));
    await this.fetch();
  }

  render() {
    const data = this.state.data;
    const list = Object.keys(data);

    return (
      <div className='dashboard'>
        <h1>Plang-Plang Counter</h1>
        <div className='container'>
          <div className='row g-3'>
            {list.map((l, i) => (
              <div className='col-md-4' key={i}>
                <div className='card'>
                  <div className='card-body'>
                    <h3 className='card-title'>{l}</h3>
                    <p className='score'>{data[l]}</p>
                    <button type="button" className="btn btn-primary btn-lg btn-block" onClick={() => this.increment(l)}><i className="bi bi-chevron-double-up"></i></button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

}

export default App;
